import './testi.css';
import {testimonialsData} from "../../data/testimonialsData";
import { useState } from 'react';
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png';
import { motion } from 'framer-motion';

function Testimonial(){
    const[select,setSelect]=useState(0);
    const length=testimonialsData.length;
    const transition={type:"spring" ,duration:3};
    

    return(
        <div className="testi" id="testi_123">
            <div className="left-t">
                <span>Testimonials</span>
                <span className="stroke-test">What they</span>
                <span>say about us</span>
                <span>
                    {testimonialsData[select].review}
                </span>
                <span>
                    {testimonialsData[select].name}
                </span>{" "}
                ~ {testimonialsData[select].status}
            </div>
            <div className="right-t">
                <motion.div initial={{opacity:0,x:-100}} 
                transition={{...transition,duration:2}}
                whileInView={{opacity:1,x:0}}></motion.div>
                <motion.div initial={{opacity:0,x:100}} 
                transition={{...transition,duration:2}}
                whileInView={{opacity:1,x:0}}></motion.div>
                <motion.img key={select} initial={{opacity:0,x:100}} animate={{opacity:1,x:0}} exit={{opacity:0,x:-100}}
                transition={{...transition,duration:2}}
                 src={testimonialsData[select].image} alt=""></motion.img>
            
                <div className="arrow"> 
                    <img src={leftArrow} alt="" 
                    onClick={()=> select===0? setSelect(length-1): setSelect((prev)=>prev-1)    
                    }/>
                    <img src={rightArrow} alt=""
                    onClick={()=> select===length-1? setSelect(0): setSelect((prev)=>prev+1) }
                    
                    />
                </div>

            </div>
        </div>

    )
}

export default Testimonial;