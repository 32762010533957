import './Hero.css'
import Header from './header/header';
import Hero_img from '../../assets/hero_image.png';
import Hero_img_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';
function Hero(){
    const transition={type: 'spring', duration:3};
    const mobile=window.innerWidth<=768 ?true:false;
    return(
        <div className="hero" id="hero123">
            <div className="blur  hero-blur" ></div>
            <div className='left-h'>
                <Header></Header>
                <div className="the-best-ad">
                    <motion.div
                    initial={{left:mobile? "168px":"238px"}}
                    whileInView={{left:'8px'}}
                    transition={{...transition,type:'tween'}}
                    ></motion.div>
                    <span>the best fitness club in the town</span>
                </div>
                {/* for-heading */}
                <div className="hero-text">
                    <div>
                        <span className="stroke-test">Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal body</span>
                    </div>
                    <div className="span">
                        <span>
                            In here we will help you to shape and build your ideal body and live up your life to fullest
                        </span>
                    </div>
                </div>
                {/* figure  */}
                <div className="figure">
                    <div>
                        <span><NumberCounter end={140} start={100} delay='4' prefix="+"></NumberCounter></span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span><NumberCounter end={978} start={800} delay='4' prefix="+"></NumberCounter></span>
                        <span>member joined</span>
                    </div>
                    <div>
                        <span><NumberCounter end={50} start={0} delay='4' prefix="+"></NumberCounter></span>
                        <span>fitness program</span>
                    </div>
                </div>
                <div className="hero-btn">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>

                
                </div>
            <div className='right-h'>
                <button className="btn">Join Here</button>
                <motion.div
                initial={{right:"-1rem"}} 
                whileInView={{right:"4rem"}} 
                transition={transition}
                className="heart-rate">
                    <img src={Heart} alt=""/>
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>
                {/* hero-image */}
                <img src={Hero_img} alt="" className="hero-img" />
                <motion.img src={Hero_img_back} alt="" className="hero-img-back" initial={{right:'11rem'}} whileInView={{right:'20rem'}} transition={transition}/>
                 {/* calories  */}
                 <div className="calories">
                    <img src={Calories} alt="" />
                    <div className="calories-div">
                    <span>Calories..Burn</span>
                    <span>220 kcal</span>
                    </div>
                 </div>
            </div>
            

        </div>



    )
}
 export default Hero;