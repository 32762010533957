import { plansData } from "../../data/plansData";
import './plan.css';
import whitetick from  '../../assets/whiteTick.png';

function Plan(){

    return(
        <div className="plans-container" id="myplan_123">
            <div className="blur   plan-blur-1"></div>
            <div className="blur   plan-blur-2"></div>
            <div className="program-header" style={{gap:"2rem"}}>
                <span className="stroke-test">READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className="stroke-test">NOW WITH US</span>
            </div>
            {/* plan-cards  */}
            <div className="plans">
                {plansData.map((data,i)=>(
                    <div className="plan" key={i}>
                        {data.icon}
                        <span>{data.name}</span>
                        <span>${data.price}</span>
                      
                    
                    <div className="features">
                        {data.features.map((data,i)=>(
                            <div className="feature">
                                <img src={whitetick} alt=""></img><span key={i}>{data}</span>
                                
                            </div>
                        ))}

                    </div>
                    <div>
                        <span>See More Benefits</span>
                    </div>
                    <button className="btn">Join Now</button>

                    </div>
                ))}

            </div>
        </div>

    )
}
export default Plan;