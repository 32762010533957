import './footer.css';
import React from 'react';
import Github from '../../assets/github.png';
import Linkdin from '../../assets/linkedin.png';
import Logo from '../../assets/logo.png';
import Instagram from '../../assets/instagram.png';

function Footer(){
    return(
        <div className="footer-container">
            <hr/>
            <div className="footer">
                <div className="social-link">
                <img src={Github} alt="" />
                <img src={Instagram} alt="" />
                <img src={Linkdin} alt="" />
            </div>

            <div className="Logo-f">
                <img src={Logo} alt=""/>
            </div>     
        
        
        </div>
        <div className="blur  footer-blur-1"></div>
        <div className="blur  footer-blur-2"></div>
        </div>
    )
}

export default Footer;