import './header.css'
import Logo from '../../../assets/logo.png';
import Bars from '../../../assets/bars.png';
import { useState } from 'react';
import { Link } from 'react-scroll';
// import { useState } from 'react';
function Header(){
    const mobile=window.innerWidth<=768? true:false;
    const[open,setopen]=useState(false);

    return(
        <div className="header">
            <img src={Logo} alt="logo1" className='logo'/>
            {(open===false && mobile===true)? (<div 
            style={{padding:'0.5rem',borderRadius:'5px',backgroundColor:'var(--appColor)'}} onClick={()=>setopen(true
            )}>
                <img src={Bars} 
                   style={{width:'1.5rem',height:'1.5rem'}} alt="">
                </img>
                </div>):
            <ul className="header-menu">
                    <li onClick={()=>setopen(false)}><Link to='hero123' span={true} smooth={true} onClick={()=>setopen(false)}>Home</Link></li>
                    <li onClick={()=>setopen(false)}><Link to='program' span={true} smooth={true} onClick={()=>setopen(false)}>Program</Link></li>
                    {/* <li onClick={()=>setopen(false)}><link>Home</link>Program</li> */}
                    <li onClick={()=>setopen(false)}><Link to='join-us' span={true} smooth={true} onClick={()=>setopen(false)}>Why us</Link></li>
                    <li onClick={()=>setopen(false)}><Link to="myplan_123" onClick={()=>setopen(false)}>Plans</Link></li>
                    <li onClick={()=>setopen(false)}><Link to='testi_123' span={true} smooth={true} onClick={()=>setopen(false)}>Testimonials</Link></li>
                    
                </ul>}
            
        </div>

    )
}
export default Header;