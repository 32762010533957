import './join.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


function Join() {
  const form = useRef();
  function handleclick(e){
    e.preventDefault();

    emailjs
      .sendForm(
        'service_ccbrfna',
        'template_5oww0tc',
        form.current,
        'cRjr8LjKW23_5YSq4'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }


 

  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-test">READY TO </span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-test"> WITH US?</span>
        </div>
      </div>
      <div className="right-j">
        <form className="email" ref={form}>
          <input type="email" name="user_email" placeholder="Enter your Email Address" />
          <button className="btn-j" type="submit" onClick={handleclick}>Join Now</button>
        </form>
      </div>
    </div>
  );
}

export default Join;


