import './App.css';
import Hero from './components/hero/Hero';
import Join from './components/join/join';
import Plan from './components/plan/plan';
import Program from './components/program/program';
import Reason from './components/reason/reason';
import Testimonial from './components/testimo/texti';
import Footer from './components/footer/footer';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/>
          <Reason/>
          <Plan/>
          <Testimonial/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
