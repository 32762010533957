import './program.css'
import { programsData } from '../../data/programsData';
import Rightarrow from '../../assets/rightArrow.png';
function Program(){


    return(
        <div className="Programs" id="program">
            {/* header */}
            <div className="program-header">
                <span className="stroke-test">Explore out</span>
                <span >Programs</span>
                <span className="stroke-test">to shape you</span>
            </div>
            <div className="program-category">
                {programsData.map((data)=>
                <div className="category">
                {data.image}    
                <span>{data.heading}</span>
                <span>{data.details}</span>
                <div className="join-now">
                    <span>Join Now</span>
                    <img src={Rightarrow} alt=""></img>
                </div>
                </div>
                
                )}
            </div>
        </div>
          

    )
}
export default Program;